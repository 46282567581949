<template>
  <div v-loading.fullscreen.lock='loading' element-loading-text="拼命加载中" element-loading-background="rgba(255, 255, 255, 0.5)">
     <el-select v-model="pageInfo.profileId" style="margin-bottom:15px;width:150px;margin-right:10px" placeholder="请选择店铺">
        <el-option-group
          v-for="group in shopList"
          :key="group.id"
          :label="group.name">
          <el-option
              v-for="item in group.marketPlaceTree"
              :key="item.profileId"
              :label="group.name+'-'+item.name"
              :value="item.profileId">
              <p @click="getID(item.profileId,item.currency,group.id,item.id)">{{item.name}}</p>
          </el-option>
      </el-option-group>
    </el-select>
    <TimeQuantum style="margin-right:10px;" section='近7天' @selectFinish='getTimeSection'></TimeQuantum>
    <el-select clearable @clear="resetList" v-model="pageInfo.portfolioId" style="width:150px;margin-right:10px" filterable placeholder="请输入/直接选择组" @change="find">
        <el-option
        v-for="item in portfoliosList"
        :key="item.id"
        :label="item.value"
        :value="item.id"
        @click.native="getCampaignsAndGroupList(item.id)">
        </el-option>
    </el-select>
    <el-select clearable @clear="resetGroupList" v-model="pageInfo.campaignId" style="width:150px;margin-right:10px" filterable placeholder="选择广告活动" @change="find">
        <el-option
        v-for="item in screenCampaignsList"
        :key="item.id"
        :label="item.value"
        :value="item.id"
        @click.native="getGroupId(item.id,item.portfolioId)">
        <p>
            <span>({{item.type}}) </span>
            <span v-if="item.state=='enabled'" style="color:#1AFA29" class="iconfont icon-duihao"></span>
            <span v-if="item.state=='paused'" style="font-size:14px;color:red" class="iconfont icon-zantingtingzhi"></span>
            <span v-if="item.state=='archived'" style="font-size:14px;color:#DCDFE6" class="iconfont icon-guidang"></span>
            <span> {{item.value}}</span>
        </p>
        </el-option>
    </el-select>
    <el-select clearable v-model="pageInfo.groupId" style="width:150px;margin-right:10px" filterable placeholder="选择广告组" @change="find">
        <el-option
        v-for="item in screenGroupList"
        :key="item.id"
        :label="item.value"
        :value="item.id"
        @click.native="pageInfo.campaignId=item.campaignId">
        <p>
            <span>({{item.type}}) </span>
            <span v-if="item.state=='enabled'" style="color:#1AFA29" class="iconfont icon-duihao"></span>
            <span v-if="item.state=='paused'" style="font-size:14px;color:red" class="iconfont icon-zantingtingzhi"></span>
            <span v-if="item.state=='archived'" style="font-size:14px;color:#DCDFE6" class="iconfont icon-guidang"></span>
            <span> {{item.value}}</span>
        </p>
        </el-option>
    </el-select>
    
    <el-select clearable v-model="pageInfo.state" style="width:150px;margin-right:10px" filterable placeholder="选择广告状态" @change="find">
        <el-option
        v-for="item in adProductStateList"
        :key="item.id"
        :label="item.value"
        :value="item.id">
        </el-option>
    </el-select>
    <el-select clearable v-model="pageInfo.campaignStatus" style="width:150px;margin-right:10px" filterable placeholder="选择广告活动状态" @change="find">
        <el-option
        v-for="item in campaignStatesList"
        :key="item.id"
        :label="item.value"
        :value="item.id">
        </el-option>
    </el-select>
    <el-select clearable v-model="pageInfo.targetType" style="width:150px;margin-right:10px" filterable placeholder="所有投放" @change="find">
        <el-option
        v-for="item in matchTypeList"
        :key="item.id"
        :label="item.value"
        :value="item.id">
        </el-option>
    </el-select>
    <el-select clearable v-model="pageInfo.isFavorite" style="width:150px;margin-right:10px"   filterable placeholder="关注状态" @change="find">
        <el-option
        v-for="item in favoriteTypeList"
        :key="item.id"
        :label="item.value"
        :value="item.id">
        </el-option>
    </el-select>
    <el-input clearable style="width:300px;margin-right:10px" placeholder="请输入ASIN" v-model="keyword" class="input-with-select" @keyup.enter.native = "find">
        <el-select clearable @clear="clearType" style="width:130px" v-model="select" slot="prepend" placeholder="选择输入类型" @change="find">
            <el-option 
            :label="item.value"
            :value="item.id"
            v-for="item in searchList"
            :key="item.id"></el-option>
        </el-select>
    </el-input>
    <!-- <div style="margin-top:5px"> -->
    <el-button type="primary" @click="find">立即查询</el-button>
    <el-button type="primary" style="margin-right:10px" @click="addGroup">新增推广产品</el-button>
    <el-dropdown  trigger='click' style="margin-right:10px">
        <el-button type="primary">
            批量操作<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item 
            v-for="(item) in adProductOperateType"
            :key="item.id"
            @click.native="batchUpdate(item.id)"
            >{{item.value}}</el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
    <tableDialog style="float:right" @setTableHead='setHead' :tableList='headList'></tableDialog>
    <!-- </div> -->
    <el-table
    :data="tableData"
    @sort-change="sortTable"
    style="width: 100%;margin-top:10px"
    @selection-change="handleSelectionChange"
    :cell-style="{ fontSize: '13px', padding: '7px 0',textAlign:'center' }"
    :header-cell-style="{
        fontSize: '13px',
        padding: '9px 0',
        marginTop: '10px',
        textAlign:'center',
        background:'#EBEEF5'
    }">
        <el-table-column
        type="selection"
        :selectable="selectable"
        width="45">
        </el-table-column>
        <el-table-column
            prop="date"
            label="商品信息"
            width="240"
            fixed="left">
            <template slot-scope="scope">
                <GoodsImage
                :marketPlaceImageUrl="scope.row.marketPlaceImageUrl"
                :label="scope.row.label"
                :parentASIN="scope.row.parentAsin"
                :imageUrl="scope.row.imageUrl"
                :asin="scope.row.asin"
                :title="scope.row.title"
                :commoditySku="scope.row.commoditySku"
              ></GoodsImage>
            </template>
        </el-table-column>
        <el-table-column
            prop="prop"
            label="启停/分析"
            width="120"
            fixed="left">
            <template slot-scope="scope">
                <div style="display:flex;justify-content:space-around;align-items:center;padding:0 20px">
                    <el-switch 
                    v-if="scope.row.state!='archived'"
                    v-model="scope.row.state"
                    active-color="#13ce66" 
                    inactive-color="#C1C1C1"
                    active-value="enabled"
                    inactive-value="paused"
                    @change="changeStatus(scope.row.adId,scope.row.state,scope.$index)"
                    ></el-switch>
                    <el-link v-else-if="scope.row.state=='archived'" disabled class="el-icon-document" style="font-size:25px;margin-right:10px"></el-link>
                    <el-tooltip effect="dark" placement="top" content="查看历史趋势">
                      <el-link class="iconfont icon-jingyingfenxi" style="font-size:24px" @click="getChart(scope.row)"></el-link>
                    </el-tooltip>
                </div>
            </template>
        </el-table-column>
        <el-table-column
            prop="prop"
            label="广告组"
            width="150"
            fixed="left">
            <template slot-scope="scope">
                <el-link>{{scope.row.adGroupName}}</el-link>
            </template>
        </el-table-column>
        <el-table-column
            header-align="center"
            align="center"
            :sortable="item.sortable?'custom':false"
            :prop="item.prop"
            label="label" 
            v-for="item in tableHead"
            :key='item.name'
            :min-width="item.width">
            <template slot="header">
                <span v-if="item.name=='定时策略'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:420px">
                            <p>广告对象定时策略绑定情况。</p>
                            <p>悬停策略类型名称上，可以查看策略的名称、状态、生效状态和应用策略状态。</p>
                            <p>点击策略类型名称，可以查看策略的详情、启停应用状态和修改分时计算基准值。</p>
                            <p>策略类型名称如果是<span style="color:#BF8350">灰色字体，表示策略当前处于不被执行状态</span>（策略未生效、策略被暂停、对象暂停应用了策略）。</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='曝光量'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:220px">
                            <p>广告投放给买家的展示总数。</p><br>
                            <p>没有曝光量就无法带来点击及购买转化，因此整体预算内，曝光量越高越好。</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='点击量'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:350px">
                            <p>点击量：广告被点击的总次数。</p>
                            <p>点击率：每一次曝光被点击的概率。</p><br>
                            <p>计算规则：广告点击率 = 点击量 / 曝光量 * 100%；</p><br>
                            <p>亚马逊广告点击率一般在0.6%-2.5%左右。</p>
                            <p>点击率高：说明选词匹配度越高，同时您的商品标题、主图、价格、评论数量，较好的吸引用户点击。</p>
                            <p>点击率低：建议对选词，商品标题、主图、价格、评论数量进行优化。</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='点击率'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:350px">
                            <p>点击量：广告被点击的总次数。</p>
                            <p>点击率：每一次曝光被点击的概率。</p><br>
                            <p>计算规则：广告点击率 = 点击量 / 曝光量 * 100%；</p><br>
                            <p>亚马逊广告点击率一般在0.6%-2.5%左右。</p>
                            <p>点击率高：说明选词匹配度越高，同时您的商品标题、主图、价格、评论数量，较好的吸引用户点击。</p>
                            <p>点击率低：建议对选词，商品标题、主图、价格、评论数量进行优化。</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='花费'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:350px">
                            <p>广告被点击，所产生的总费用，相当于您的广告总成本 。</p><br>
                            <p>1、因近30天（尤其近3天）亚马逊接口返回的数据，可能与亚马逊控制台展现的数据存在略微不一致，因此可能导致系统统计结果与亚马逊控制台展现的数据存在略微不一致；</p><br>
                            <p>2、以商品和站点不同维度查看数据，因数据来自亚马逊不同的接口，不同接口的请求时间或者报告生成时间可能不一致，因此可能导致两个维度下统计结果存在略微不一致；</p>
                            <p>3、计算规则：广告花费 = CPC竞价 * 被点击次数；</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='CPC均价'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:350px">
                            <p>CPC点击的平均价格。</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='订单数'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:350px">
                            <p>通过广告带来的订单量。</p><br>
                            <p>1、因近30天（尤其近3天）亚马逊接口返回的数据，可能与亚马逊控制台展现的数据存在略微不一致，因此可能导致系统统计结果与亚马逊控制台展现的数据存在略微不一致；</p>
                            <p>2、以商品和站点不同维度查看数据，因数据来自亚马逊不同的接口，不同接口的请求时间或者报告生成时间可能不一致，因此可能导致两个维度下统计结果存在略微不一致；</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='CVR'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:300px">
                            <p>每一次点击带来订单的概率。</p><br>
                            <p>计算规则：CVR = 订单数 / 点击量 * 100%；</p>
                            <p>例如：CVR为10%，每100次点击，能带来10笔广告订单。</p><br>
                            <p>亚马逊广告，CVR一般为5-10%左右，</p>
                            <p>CVR的重要影响因素：商品图片、卖点、评论内容、促销活动，如果CVR低，建议您优化listing，根据商品的受众人群，提炼卖点、设计精美的场景图、善用Q&A，Review等。</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='CPA'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:300px">
                            <p>每一笔广告订单平均所需花费用</p><br>
                            <p>计算规则：CPA = 花费 / 订单数；</p><br>
                            <p>商品的新品期，CPA相对较高，</p>
                            <p>商品的稳定期，CPA逐渐降低。</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='销售额'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:300px">
                            <p>通过广告带来的销售额。</p><br>
                            <p>1、因近30天（尤其近3天）亚马逊接口返回的数据，可能与亚马逊控制台展现的数据存在略微不一致，因此可能导致系统统计结果与亚马逊控制台展现的数据存在略微不一致；</p>
                            <p>2、以商品和站点不同维度查看数据，因数据来自亚马逊不同的接口，不同接口的请求时间或者报告生成时间可能不一致，因此可能导致两个维度下统计结果存在略微不一致；</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='ACoS'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:300px">
                            <p>广告销售成本率，广告花费占广告销售额的比例。</p><br>
                            <p>计算规则：ACoS = 广告花费 / 广告销售额 * 100%；</p>
                            <p>例如，ACoS为90%，100美元的销售额，需要支付90%（90元）广告费。</p>
                            <p>ACoS是衡量广告效果的重要指标，一般来说越低越好，</p>
                            <p>25%是业内普遍的衡量标准，</p>
                            <p>但并不能用ACoS指标单一来衡量广告的效果。</p><br>
                            <p>在不同的细分市场、商品周期、用户周期，品牌定位，都影响着ACoS的设置目标，</p>
                            <p>例如，客户复购率高、忠诚度高、终身价值高，或是有品牌光环和生态的商品，</p>
                            <p>通过广告一次获客，客户可带来长期的购买回报，</p>
                            <p>因此这类商品ACoS高，反而有益于创造更多利润。</p><br>
                            <p>拓展阅读<a href="https://www.baidu.com/s?ie=UTF-8&wd=ACoS%E8%B6%8A%E4%BD%8E%E8%B6%8A%E5%A5%BD%E5%90%97%EF%BC%9F&tn=62095104_41_oem_dg" style="color:#13CE66">ACoS越低越好吗？</a></p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='ROAS'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:300px">
                            <p>广告投入回报比，广告销售额与成本的倍数。</p><br>
                            <p>计算规则：RoAS = 销售额 / 花费；</p>
                            <p>例如，RoAS为2，说明花10美元做广告，带来了20美元的销售额。</p><br>
                            <p>RoAS是衡量广告效果投入成本的效率指标，一般情况下，越大越好，亚马逊上的平均RoAS约为3，消费类电子产品的RoAS约为9，玩具和游戏的RoAS约为4.5，</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else>{{item.name}}</span>
            </template>
            <template slot-scope="scope">
                <span v-if="item.name=='活动状态'">
                    <span v-if="scope.row.campaignState=='已启用'" style="color:#13CE66">{{scope.row.campaignState}}</span>
                    <span v-if="scope.row.campaignState=='已暂停'" style="color:red">{{scope.row.campaignState}}</span>
                    <span v-if="scope.row.campaignState=='已归档'" style="color:#999999">{{scope.row.campaignState}}</span>
                </span>
                <span v-else-if="item.name=='点击率'">
                    <span>{{scope.row.clickRate}}</span><span v-if="scope.row.clickRate!='-'"></span>
                </span>
                <span v-else-if="item.name=='CVR'">
                    <span>{{scope.row.cvr}}</span><span v-if="scope.row.cvr!='-'">%</span>
                </span>
                <p v-else>
                    <span v-if="item.currency&&scope.row[item.prop]!='-'">{{currency}}</span>
                    {{scope.row[item.prop]}}
                </p>
            </template>
        </el-table-column>
    </el-table>
    <div style="text-align:center;margin:10px">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.current"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>
    <el-dialog
        title="新增推广产品"
        :visible.sync="addGroupBox"
        width="65%"
        :before-close="addGroupBoxClose">
        <div>
            <el-form label-width="100px" :model="addInfo" :rules="rules" style="padding:0 80px">
                <el-form-item label="广告活动" prop="campaignId">
                    <el-select 
                    clearable
                    filterable
                    v-model="addInfo.campaignId"
                    placeholder="请选择广告活动"
                    style="width:85%">
                        <el-option 
                        :value="item.id"
                        :label="item.value"
                        v-for="item in addInfo.campaignsList"
                        :key="item.id"
                        @click.native='getScreenGroupsList(item)'>
                            <template>
                                <span>({{item.type}}) </span>
                                <span v-if="item.state=='enabled'" style="color:#1AFA29" class="iconfont icon-duihao"></span>
                                <span v-if="item.state=='paused'" style="font-size:14px;color:red" class="iconfont icon-zantingtingzhi"></span>
                                <span v-if="item.state=='archived'" style="font-size:14px;color:#DCDFE6" class="iconfont icon-guidang"></span>
                                <span> {{item.value}}</span>
                            </template>
                        </el-option>
                    </el-select>
                    <span style="color:#999999;margin-left:15px" v-if="addInfo.campaignId!=''" @click="activeInfo">活动信息<span v-if="!showActiveInfo">(查看)</span><span v-if="showActiveInfo">(隐藏)</span></span>
                </el-form-item>
                <div class="activeInfo" v-if="showActiveInfo">
                    <div><span>广告组合：</span><span>{{detailActive.value}}</span></div>
                    <div><span>活动起止日期：</span><span>{{detailActive.date}}</span></div>
                    <div><span>每日预算：</span><span>{{currency}}{{detailActive.bid}}</span></div>
                    <div><span>定向策略：</span><span>{{detailActive.targetingType}}</span></div>
                    <div><span>活动竞价策略：</span><span>{{detailActive.target}}</span></div><br>
                    <div><span>商品页面：</span><span>{{detailActive.page}}</span></div>
                    <div><span>搜索结果顶部：</span><span>{{detailActive.top}}</span></div>
                </div>
                <el-form-item label="广告组" prop="adGroupId">
                    <el-select 
                    clearable
                    filterable
                    v-model="addInfo.adGroupId"
                    placeholder="请选择广告组"
                    style="width:85%">
                        <el-option 
                        :value="item.id"
                        :label="item.value"
                        v-for="item in addInfo.screenGroupsList"
                        :key="item.id"
                        @click.native='getExistGoods(item.id,item.type,item)'>
                            <template>
                                <span>({{item.type}}) </span>
                                <span v-if="item.state=='enabled'" style="color:#1AFA29" class="iconfont icon-duihao"></span>
                                <span v-if="item.state=='paused'" style="font-size:14px;color:red" class="iconfont icon-zantingtingzhi"></span>
                                <span v-if="item.state=='archived'" style="font-size:14px;color:#DCDFE6" class="iconfont icon-guidang"></span>
                                <span> {{item.value}}</span>
                            </template>
                        </el-option>
                    </el-select>
                    <span style="color:#999999;margin-left:15px" v-if="addInfo.adGroupId!=''" @click="groupInfo">广告组信息<span v-if="!showGroupInfo">(查看)</span><span v-if="showGroupInfo">(隐藏)</span></span>
                </el-form-item>
                <div class="activeInfo" v-if="showGroupInfo">
                    <div><span>定向策略：</span><span>{{detailActive.groupTargetType}}</span></div>
                    <div><span>默认竞价：</span><span>{{detailActive.groupValue}}</span></div>
                </div>
                <el-form-item label="商品">
                    <template slot="label">
                        <span style="margin-right:3px">商品</span>
                        <el-tooltip placement="top">
                            <div slot="content" style="width:250px;line-height:19px">
                                <p>添加您想要在此广告活动中推广的商品。</p><br>
                                <p>1、由于系统同步非实时，因此商品状态、库存数据、售价，可能存在一定延迟；</p>
                                <p>2、分组标签为您在S2系统中设置的；</p>
                                <p>3、预计可售天数是由您在S2系统中配置的近30日销售权重和销售情况计算而得。</p>
                            </div>
                            <i class="iconfont icon-wenhao"></i>
                        </el-tooltip>
                    </template>
                    <el-select v-model="getGoodsInfo.status" style="width:140px;margin:0px 10px 0 0px" placeholder="商品状态">
                    <el-option 
                    :label="item.value" 
                    :value="item.id" 
                    v-for="item in productStatusList"
                    :key='item.id'></el-option>
                </el-select>
                    <!-- <el-select style="width:140px;margin-right:10px" placeholder="品牌">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                    <el-select style="width:140px;margin-right:10px" placeholder="分组标签">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                    </el-select> -->
                    <el-input clearable v-model="searchByAsin" style="width:300px;margin-right:10px;margin-top:2px" placeholder="请输入ASIN"  class="input-with-select">
                        <el-select clearable @clear="clearType" style="width:130px" v-model="selectAsinType" slot="prepend" placeholder="选择输入类型">
                            <el-option 
                            :label="item.value"
                            :value="item.id"
                            v-for="item in searchList"
                            :key="item.id"></el-option>
                        </el-select>
                    </el-input>
                    <el-button type="primary" size="mini" @click="searchGoods">立即查询</el-button>
                </el-form-item>
            </el-form>
            <div class="Box" v-loading='dialogLoading'>
                <div>
                    <el-tabs v-model="activeName">
                        <el-tab-pane label="搜索" name="first"></el-tab-pane>
                        <el-tab-pane label="输入列表" name="second">
                            <span slot="label" v-if="activeName=='second'">
                                <span style="margin-right:10px">输入列表</span>
                                <el-radio v-model="inputList" label="asin">子ASIN</el-radio>
                                <el-radio v-model="inputList" label="parent_asin">父ASIN</el-radio>
                            </span>
                        </el-tab-pane>
                    </el-tabs>
                    <el-button type="text" class='all' @click="GoodsAll('add')">添加全部</el-button>
                    <div v-if="activeName=='first'" v-loading='dialogLoading'>
                        <div style="height:450px;overflow:auto">
                            <div class="item" v-for="item in goodsList" :key="item.id">
                                <div class="pic">
                                    <img :src="item.imageUrl" alt="" width="100%" height="100%">
                                </div>
                                <div class="SKU">
                                    <p>
                                        <span class="state"><img :src="item.marketPlaceImageUrl" alt="" width="100%"></span><span>子：{{item.asin}}</span>
                                    </p>
                                    <p>
                                        <span>SKU：{{item.commoditySku}}</span>
                                    </p>
                                </div>
                                <div class="realizable">
                                    <p>可售库存：{{item.total}}</p>
                                    <p>可售天数：{{item.canSellDay}}</p>
                                </div>
                                <div class="price">
                                    <p>售价：{{currency}}{{item.sellPrice}}</p>
                                </div>
                                <div style="font-weight:blod;font-size:23px;color:#409EFF;width:30px"><span @click="toAdded(item)" v-if="item.operation==0" class="el-icon-circle-plus-outline"></span></div>
                            </div>
                        </div>
                        <div style="text-align:right;padding-top:20px">
                            <el-pagination
                            background
                            @current-change='changeCurrent'
                            :current-page.sync="getGoodsInfo.current"
                            layout="prev, pager, next"
                            :total="goodsTotal">
                            </el-pagination>
                        </div>
                    </div>
                    <div v-if="activeName=='second'">
                        <el-input 
                        v-model="manualASIN"
                        type="textarea" rows="14" 
                        placeholder="手动输入ASIN,多个时换行输入 如 
B077ZKF9ZN 
B07X1QPRYQ 
B07V2CLJLV" 
                        style="margin-top:15px"></el-input>
                        <el-button style="float:right;margin-top:10px" @click="manualAdd">添加</el-button>
                    </div>
                </div>
                <div>
                    <el-tabs v-model="added">
                        <el-tab-pane :label="'已添加'+'('+addedList.length+')'" name="first"></el-tab-pane>
                    </el-tabs>
                    <el-button type="text" class='all' @click="delAllGoodsLaunch">删除全部</el-button>
                    <div style="height:450px;overflow:auto" v-loading='dialogLoading'>
                        <template  v-for="(item,index) in addedList" >
                        <div class="item" :key="item.id">
                            <div class="pic">
                                <img :src="item.imageUrl" alt="" width="100%">
                            </div>
                            <div class="SKU">
                                <p>
                                    <span class="state"><img :src="item.marketPlaceImageUrl" alt="" width="100%"></span><span>子：{{item.asin}}</span>
                                </p>
                                <p>
                                    <span>SKU：{{item.commoditySku}}</span>
                                </p>
                            </div>
                            <div class="realizable">
                                <p>可售库存：{{item.total}}</p>
                                <p>可售天数：{{item.canSellDay}}</p>
                            </div>
                            <div class="price">
                                <p>售价：{{currency}}{{item.sellPrice}}</p>
                            </div>
                            <div v-if="item.yet!=1" style="font-weight:blod;font-size:23px;color:#409EFF;width:30px"><span  @click="toGoods(item,index)" class="el-icon-delete"></span></div>
                            <div v-if="item.yet==1" style="color:#999999;font-size:14px;width:50px">已存在</div>
                        </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div style="text-align:right;margin-right:40px;margin-bottom:20px">
            <el-button type="primary" :loading='dialogLoading' @click="sureAdd">确定新增商品</el-button>
        </div>
        <el-divider v-if="putStatus!=''">
            <p @click='unfold'>
                <span style="font-size:18px">投放/否定投放 </span>
                <span v-if="!active">展开 <el-link type='primary'>(同时维护投放信息)</el-link></span>
                <span v-if="active">隐藏 <el-link type='primary'>(仅新增推广商品)</el-link></span>
            </p>
        </el-divider>
        <div v-loading='buttonLoading' v-if="active" style="margin-top:50px">
            <!-- 细化 -->
            <el-dialog
            width="40%"
            :visible.sync="elaborationBox"
            :before-close="elaborationClose"
            append-to-body>
                <template slot="title">
                    <p>细化产品分类：&nbsp;</p>
                    <p style="color:#999999;font-size:14px;margin-top:10px;border-bottom:1px #D9D9D9 solid;padding-bottom:10px">根据特定品牌、价格范围、星级和Prime配送资格，细化分类</p>
                </template>
                <div style="margin-top:-20px;padding:0 20px">
                    <p>品牌</p>
                    <el-select
                        style="width:100%;margin:10px 0"  placeholder="请输入或选择品牌"
                        v-model="elaborationInfo.asinBrandSameAs"
                        multiple
                        filterable
                        allow-create
                        default-first-option>
                        <el-option
                        v-for="item in brandList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                    <p>商品价格范围</p>
                    <el-input v-model="elaborationInfo.asinPriceGreaterThan" placeholder="无最低商品价格" el-input style="width:180px;margin:15px 0 ">
                    <el-button slot="append">{{currency}}</el-button>
                    </el-input> -  <el-input v-model="elaborationInfo.asinPriceLessThan" placeholder="无最高商品价格" style="width:180px;margin:10px 0">
                        <el-button slot="append">{{currency}}</el-button>
                    </el-input>
                    <p>查看星级评定 0 星 ~ 5 星</p>
                    <div class="block" style="margin:15px 0 30px 0">
                        <el-slider
                        v-model="elaborationInfo.asinReviewRatingBetween"
                        range
                        :marks="marks"
                        show-stops
                        :max="5">
                        </el-slider>
                    </div>
                    <p style="margin:20px 0">配送</p>
                    <el-radio 
                    v-model="elaborationInfo.asinIsPrimeShippingEligible" 
                    :label="String(item.id)"
                    v-for="item in primeTypeList"
                    :key="item.id">{{item.value}}</el-radio>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="elaborationClose">取 消</el-button>
                    <el-button type="primary" @click="addElaboration">确 定</el-button>
                </span>
            </el-dialog>
            <!-- 商品投放 -->
            <div class="goodsPut" v-if="putStatus=='商'" style="margin-bottom:20px">
                <el-tabs v-model="putType" style="height:30px;" type="card" @tab-click="handleClick">
                    <el-tab-pane label="分类" name="asinCategorySameAs">
                        <span slot="label">分类
                            <el-tooltip placement="top">
                                <div slot="content" style="width:250px;line-height:19px">
                                    <p>各类商品是按相似性划分在一起的商品，如女士跑鞋。</p>
                                </div>
                                <i class="iconfont icon-wenhao"></i>
                            </el-tooltip>
                        </span>
                    </el-tab-pane>
                    <el-tab-pane label="各个商品" name="asinSameAs">
                        <span slot="label">各个商品
                            <el-tooltip placement="top">
                                <div slot="content" style="width:250px;line-height:19px">
                                    <p>定位到与您广告中宣传的商品类似的各个商品。</p>
                                </div>
                                <i class="iconfont icon-wenhao"></i>
                            </el-tooltip>
                        </span>
                    </el-tab-pane>
                </el-tabs>
                <el-tabs v-model="putTypeInside" style="height:30px;margin:10px" @tab-click="this.inputASIN=''">
                    <el-tab-pane v-if="putType=='asinCategorySameAs'" label="建议" name="first"></el-tab-pane>
                    <el-tab-pane v-if="putType=='asinSameAs'" label="已建议" name="second"></el-tab-pane>
                    <el-tab-pane v-if="putType=='asinSameAs'" label="手动输入" name="third"></el-tab-pane>
                </el-tabs>
                <!-- <el-tabs style="height:30px;margin:15px" @tab-click="handleClick">
                    <el-tab-pane label="建议" name="first"></el-tab-pane>
                </el-tabs> -->
                <el-table
                    v-loading='suggestLoading'
                    v-if="putTypeInside=='first'"
                    :show-header="false"
                    :data="categoriesList"
                    style="width: 100%">
                    <el-table-column
                        prop="prop"
                        label="label"
                        width="width">
                        <template slot-scope="scope">
                            <p style="color:#999999;font-size:15px">{{scope.row.path}}</p>
                            <p style="font-size:15px">分类：{{scope.row.name}}</p>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="prop"
                        label="label"
                        width="33">
                        <template slot-scope="scope">
                            <div style="display:flex;align-items:center;justify-content:space-between">
                                <span v-show="scope.row.operation==0" @click="addLocationGoods(scope.row)" style="font-size:23px;color:#409EFF;" class="el-icon-circle-plus-outline"></span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="prop"
                        label="label" 
                        width='60px'>    
                        <template slot-scope="scope"><el-link style="color:#999999;line-height:1" @click="getGroupDetailInit(scope.row.id,scope.row)">细化</el-link></template>
                    </el-table-column>
                </el-table>
                <el-table
                    v-loading='suggestedLoading'
                    height="400px"
                    v-if="putTypeInside=='second'"
                    :data="targetProductList"
                    style="width: 100%"
                    header-cell-style="background:#DFEFEFE;font-size:14px"
                    >
                    <el-table-column
                        prop="recommendedTargetAsin"
                        label="ASIN"
                        width="width">
                    </el-table-column>
                    <el-table-column
                        align="center"
                        prop="prop"
                        label="添加"
                        width="70">
                        <template slot-scope="scope">
                            <span @click="addLocationGoods(scope.row)" v-if="scope.row.operation==0" style="font-size:23px;color:#409EFF;" class="el-icon-circle-plus-outline"></span>
                        </template>
                    </el-table-column>
                </el-table>
                <div v-if="putTypeInside=='third'">
                    <el-input 
                    v-model="inputASIN"
                    style="margin-top:10px;"
                    rows="10"
                    cols='43'
                    type="textarea"
                    placeholder="手动输入ASIN,多个时换行输入 如 
B077ZKF9ZN 
B07X1QPRYQ 
B07V2CLJLV" >
                    </el-input>
                    <el-button style="float:right;margin:10px 0" @click="addLocationGoods">添加</el-button>
                </div>
                <p style="line-height:1;text-align:center;margin-top:60px">定位商品数<el-button type="text" style="float:right;margin-bottom:5px" @click="removeAll('all')">全部删除</el-button></p>
                <el-table
                    :data="addInfo.targetAddReqList"
                    style="width: 100%"
                    header-cell-style="background:#FAFAFA;font-size:14px">
                    <el-table-column
                        prop="prop"
                        label="分类和商品"
                        width="width">
                        <template slot-scope="scope" style="font-size:14px">
                            <div v-if="scope.row.yet!=1">
                                <p v-if="scope.row.amzAdTargetExpressionList[0].type=='asinCategorySameAs'" style="color:#999999;font-size:15px">{{scope.row.path}}</p>
                                <p v-if="scope.row.amzAdTargetExpressionList[0].type=='asinCategorySameAs'" style="font-size:15px">
                                    <span>分类：{{scope.row.amzAdTargetExpressionList[0].name}}</span>&ensp;&ensp;
                                    <span v-if="scope.row.name!=undefined">品牌：{{scope.row.name}}</span>&ensp;&ensp;
                                    <span v-if="scope.row.asinPriceGreaterThan!=undefined && scope.row.asinPriceGreaterThan!=''&& scope.row.asinPriceLessThan!=undefined && scope.row.asinPriceLessThan!=''">价格区间：{{scope.row.asinPriceGreaterThan + ' - ' +scope.row.asinPriceLessThan}}</span>&ensp;&ensp;
                                    <span v-if="scope.row.asinPriceLessThan!=''&&scope.row.asinPriceGreaterThan=='' || scope.row.asinPriceGreaterThan!=''&&scope.row.asinPriceLessThan==''">商品价格：
                                        <span v-if="scope.row.asinPriceLessThan!=''">不高于 {{scope.row.asinPriceLessThan}}</span>
                                        <span v-if="asinPriceGreaterThan!=''">不低于 {{scope.row.asinPriceGreaterThan}}</span>
                                    </span>&nbsp;&nbsp;
                                    <span v-if="scope.row.prime!=undefined&&scope.row.prime!=''">配送：{{scope.row.prime}}</span>&nbsp;&nbsp;
                                    <span v-if="scope.row.asinReviewRatingBetween!=undefined&&scope.row.asinReviewRatingBetween.length!=0">评分：{{scope.row.asinReviewRatingBetween[0]+'-'+ scope.row.asinReviewRatingBetween[1]}}</span>
                                </p>
                                <p v-if="scope.row.amzAdTargetExpressionList[0].type=='asinSameAs'">{{scope.row.amzAdTargetExpressionList[0].name}}</p>
                            </div>
                            <div v-if="scope.row.yet==1" v-dompurify-html="scope.row.value" style="font-size:14px"></div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="prop"
                        label="竞价"
                        width="170">
                        <template slot-scope="scope">
                            <el-input :disabled='scope.row.yet==1?true:false' style="width:120px" v-model="scope.row.bid" placeholder="竞价">
                                <el-button slot="append">{{currency}}</el-button>
                            </el-input>
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="center"
                        prop="prop"
                        label="删除"
                        width="70">
                        <template slot-scope="scope">
                            <span v-if="scope.row.yet!=1"  @click="remove(scope.$index, addInfo.targetAddReqList)" style="font-size:23px;color:#409EFF;" class="el-icon-delete"></span>
                            <span v-if="scope.row.yet==1" style="color:#999999;font-size:14px">已存在</span>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="text-align:right;margin:15px 0 15px">
                    <el-button type="primary" :loading='buttonLoading' @click="NewProductPositioning">确认新增商品定位</el-button>
                </div>
            </div>
            <!-- 关键词 -->
            <div class="Box" v-if="putStatus=='词'">
                <div>
                    <el-tabs v-model="activeWord">
                        <el-tab-pane label="建议" name="first">
                            <span slot="label">建议
                                <el-tooltip placement="top">
                                    <div slot="content" style="width:250px;line-height:19px">
                                        <p>建议关键词以您广告中的商品为基础。</p>
                                        <p>您可以添加所需的关键词，并在添加后编辑关键词和竞价。 <a style="color:#13CE66" target="top" href="https://advertising.amazon.com/help?entityId=ENTITY3VAZBWP88M62S#GK3MNACNTXG659J9">了解更多信息</a></p>
                                    </div>
                                    <i class="iconfont icon-wenhao"></i>
                                </el-tooltip>
                            </span>
                        </el-tab-pane>
                        <el-tab-pane label="输入列表" name="second"></el-tab-pane>
                    </el-tabs>
                    <p style="margin-top:15px">
                        <span style="color:#999999;margin-right:10px">筛选条件</span>
                        <el-checkbox v-model="screen" label="exact">精准</el-checkbox>
                        <el-checkbox v-model="screen" label="phrase">词组</el-checkbox>
                        <el-checkbox v-model="screen" label="broad">宽泛</el-checkbox>
                    </p>
                    <el-button type="text" class='all' @click="allKeywordlaunch">添加全部</el-button>
                    <div v-if="activeWord=='first'">
                        <div style="height:400px;overflow:auto">
                            <el-table
                                v-loading='dialogLoading'
                                height='390'
                                :data="keywordList"
                                style="width: 100%;margin-top:10px;"
                                header-cell-style="background:#FAFAFA;font-size:14px">
                                <el-table-column
                                    header-align="left"
                                    prop="prop"
                                    label="关键词"
                                    width="width">
                                    <template slot-scope="scope">
                                        <p style="font-size:14px">{{scope.row}}</p>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="prop"
                                    align="center"
                                    label="添加"
                                    width="70">
                                    <template slot-scope="scope">
                                        <span @click="addKeywords(scope.row)" style="font-size:23px;color:#409EFF;" class="el-icon-circle-plus-outline"></span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                    <div v-if="activeWord=='second'">
                        <el-input 
                        v-model="addKewordInput"
                        type="textarea" rows="14"
                        style="margin-top:15px"></el-input>
                        <el-button style="float:right;margin-top:10px" @click="addInputKeywords">添加</el-button>
                    </div>
                </div>
                <div>
                    <el-tabs v-model="activeName">
                        <!-- dijia 已添加数量标记 -->
                        <el-tab-pane :label="'已添加'+'('+addInfo.keywordAddReqList.length+')'" name="first"></el-tab-pane>
                    </el-tabs>
                    <!-- 删除全部待做 -->
                    <el-button type="text" class='all' @click="delAllkeywordLaunch">删除全部</el-button>
                    <div style="height:450px;overflow:auto">
                        <el-table
                         :data="addInfo.keywordAddReqList"
                         height='417'
                         header-cell-style="background:#FAFAFA;font-size:14px"
                         style="width: 100%;margin-top:15px"
                         cell-style="font-size:14px">
                        <el-table-column prop="keywordText" label="关键词" width="width"></el-table-column>
                        <el-table-column header-align="center" align="center" prop="matchType" label="匹配类型" width="100">
                          <template slot-scope="scope">
                            <span v-if="scope.row.matchType=='exact'">精确</span>
                            <span v-if="scope.row.matchType=='phrase'">词组</span>
                            <span v-if="scope.row.matchType=='broad'">宽泛</span>
                          </template>
                        </el-table-column>
                        <el-table-column header-align="center" align="center" prop="prop" label="竞价" width="150">
                          <template slot-scope="scope">
                            <el-input :disabled="scope.row.yet==1?true:false" style="width:120px" v-model="scope.row.bid" placeholder="竞价">
                                <el-button slot="append">{{currency}}</el-button>
                            </el-input>
                          </template>
                        </el-table-column>
                        <el-table-column header-align="center" align="center" prop="prop" label="删除" width="80">
                          <template slot-scope="scope">
                            <el-button v-if="scope.row.yet!=1" @click="delKeywords(scope.$index, addInfo.keywordAddReqList)" style="font-size:23px;" type="text" class="el-icon-delete"></el-button>
                            <span v-if="scope.row.yet==1" style="color:#999999;font-size:14px">已存在</span>
                          </template>
                        </el-table-column>
                        </el-table>
                    </div>
                    <div style="text-align:right;margin-bottom:10px">
                      <el-button type="primary" :loading='buttonLoading' @click="confirmAddNewKeywords">确认新增关键词</el-button>
                    </div>
                </div>
            </div>
            <!-- 否定关键词 -->
            <div class="words" v-if="putStatus=='自'||putStatus=='词'">
                <el-collapse accordion style="margin-bottom:18px">
                    <el-collapse-item>
                        <template slot="title">
                        <span style="margin-left:20px">否定关键词</span>
                        </template>
                        <div class="keyword" style="border-top: 1px solid #F0F0F0;padding:0 10px">
                            <div>
                                <span>匹配类型</span>
                                <el-tooltip placement="top">
                                    <div slot="content" style="line-height:19px;width:250px">
                                        <p>匹配类型让您能够微调哪些顾客搜索内容会触发您的广告。</p><br>
                                        <p>广泛匹配： 包含以任何顺序排列的所有关键词，包括复数、变体和相关关键词。</p>
                                        <p>词组匹配： 包含确切的词组或词序相同的关键词。</p>
                                        <p>精准匹配： 与相应关键词或关键词的词序完全匹配。</p>
                                    </div>
                                    <span class="iconfont icon-wenhao" style="margin-right:9px"></span>
                                </el-tooltip>
                                <el-radio v-model="matchType" label="negativeExact">否定精准</el-radio>
                                <el-radio v-model="matchType" label="negativePhrase">否定词组</el-radio><br>
                                <el-input v-model="noKeywords" rows="8" type="textarea" placeholder="输入您的列表并用新行将每项隔开"></el-input>
                                <el-button style="float:right;margin:10px 0px 0 15px" @click="addNoKey">添加关键词</el-button>
                            </div>
                            <div v-loading="dialogLoading">
                                <span style="margin-right:10px;color:#409EFF;cursor: pointer;" v-show="addedNoKeyword!=0" @click="updateNeKeyword">归档</span>
                                <span>已存在{{addedNoKeyword}}个，并添加{{addInfo.neKeywordAddReqList.length - addedNoKeyword}}</span>
                                <el-button type="text" style="float:right" @click="delAllkeyword">删除全部</el-button>
                                <el-table
                                    :data="addInfo.neKeywordAddReqList"
                                    height="300"
                                    style="width: 100%;"
                                    cell-style="font-size:14px"
                                    @selection-change="selectNoKeywords"
                                    header-cell-style="background:#FAFAFA;font-size:14px">
                                    <el-table-column
                                    type="selection"
                                    width="55"
                                    :selectable="archivedKeywordSelect"
                                    ></el-table-column>
                                    <el-table-column
                                        prop="keywordText"
                                        label="关键词"
                                        width="width">
                                    </el-table-column>
                                    <el-table-column
                                        prop="matchType"
                                        label="匹配类型"
                                        width="90">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.matchType=='negativeExact'">否定精准</span>
                                            <span v-else>否定词组</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="prop"
                                        align="center"
                                        header-align="center"
                                        label="删除"
                                        width="90">
                                        <template slot-scope="scope">
                                            <el-button v-if="scope.row.operation!=1" @click="delNegativeKeyword(scope.$index,scope.row.keywordText)" style="font-size:23px;" type="text" class="el-icon-delete"></el-button>
                                            <span v-if="scope.row.operation==1" style="color:#999999;font-size:14px">已存在</span>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                        <div style="text-align:right;margin-right:10px;margin-bottom:20px">
                            <el-button type="primary" :loading='buttonLoading' @click="addNegativeKeyword">确认新增否定关键词</el-button>
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </div> 
            <!-- 否定商品 -->
            <div class="words" v-if="putStatus=='自' || putStatus=='商'"> 
                <el-collapse accordion>
                    <el-collapse-item>
                        <template slot="title">
                            <span style="margin-left:20px">否定商品</span>
                        </template>
                        <div class="keyword" style="border-top: 1px solid #F0F0F0;padding:0 10px">
                            <div>
                                <el-tabs v-model="noGoodsType" @tab-click="noGoods=''">
                                    <el-tab-pane v-if="putStatus=='商'" label="排除品牌" name="asinBrandSameAs">
                                        <span slot="label">排除品牌
                                            <el-tooltip placement="top">
                                                <div slot="content" style="width:250px;line-height:19px">
                                                    <p>这是一项高级策略，可防止您的广告展示在特定搜索结果和商品详情页中。</p>
                                                </div>
                                                <i class="iconfont icon-wenhao"></i>
                                            </el-tooltip>
                                        </span>
                                    </el-tab-pane>
                                    <el-tab-pane label="排除全部" name="asinSameAs">
                                        <span slot="label">排除全部
                                            <el-tooltip placement="top">
                                                <div slot="content" style="width:250px;line-height:19px">
                                                    <p>这是一项高级策略，可防止您的广告展示在特定搜索结果和商品详情页中。</p>
                                                </div>
                                                <i class="iconfont icon-wenhao"></i>
                                            </el-tooltip>
                                        </span>
                                    </el-tab-pane>
                                </el-tabs>
                                <div v-if="noGoodsType=='asinSameAs'">
                                    <el-input v-model="noGoods" style="margin-top:10px" rows="8" type="textarea" placeholder="手动输入ASIN，多个时换行输入"></el-input>
                                    <el-button @click="toTargetsBrandsList" style="float:right;margin:10px 0px 0 15px">添加</el-button>
                                </div>
                                <div v-if="noGoodsType=='asinBrandSameAs' && putStatus=='商'">
                                    <el-input placeholder="请输入内容" v-model="targetsBrandsInfo.keyword" class="input-with-select" style="margin-top:10px">
                                        <el-button :loading='searchLoading' @click="getTargetsBrandsList" slot="append" icon="el-icon-search"></el-button>
                                    </el-input>
                                    <div>
                                        <el-table
                                            v-loading='searchLoading'
                                            height="300px"
                                            :show-header="false"
                                            :data="targetsBrandsList"
                                            style="width: 100%;"
                                            cell-style="font-size:14px"
                                            header-cell-style="background:#FAFAFA;font-size:14px">
                                            <el-table-column
                                                prop="name"
                                                label="label"
                                                width="width">
                                            </el-table-column>
                                            <el-table-column
                                                prop="prop"
                                                label="label"
                                                width="50">
                                                <template slot-scope="scope">
                                                    <span v-if="scope.row.operation==0" @click="toTargetsBrandsList(scope.row,scope.$index)" style="font-size:23px;color:#409EFF;" class="el-icon-circle-plus-outline"></span>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </div>
                                </div>
                            </div>
                            <div v-loading='dialogLoading'>
                                <span style="margin-right:10px;color:#409EFF;cursor: pointer;" v-show="addedNoGoods!=0"  @click="archivedNeTarget">归档</span>
                                <span>已存在{{addedNoGoods}}个，并添加{{addInfo.neTargetAddReqList.length - addedNoGoods}}</span>
                                <el-button type="text" style="float:right" @click="delAllnoGood">删除全部</el-button>
                                <el-table
                                    @selection-change="selectNoGoods"
                                    :data="addInfo.neTargetAddReqList"
                                    style="width: 100%"
                                    header-cell-style="background:#DFEFEFE;font-size:14px">
                                    <el-table-column
                                    type="selection"
                                    width="50"
                                    :selectable="archivedSelect"
                                    ></el-table-column>
                                    <el-table-column
                                        prop="prop"
                                        label="品牌/商品"
                                        width="width">
                                        <template slot-scope="scope">
                                            <p style="color:#999999;font-size:14px">
                                                <span v-if="scope.row.type=='asinBrandSameAs'&&scope.row.yet!=1">品牌：{{scope.row.name}}</span>
                                                <span v-if="scope.row.type=='asinSameAs'&&scope.row.yet!=1">ASIN：{{scope.row.value}}</span>
                                                <span v-if="scope.row.yet==1">
                                                    <span v-if="scope.row.type=='asinBrandSameAs'">品牌：</span>
                                                    <span v-if="scope.row.type=='asinSameAs'">ASIN：</span>
                                                    {{scope.row.value}}
                                                </span>
                                            </p>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="prop"
                                        align="center"
                                        header-align="center"
                                        label="删除"
                                        width="70">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.yet!=1" style="font-size:23px;color:#409EFF;" class="el-icon-delete" @click="delItem(scope.$index,scope.row.name)"></span>
                                            <span style="color:#999999;font-size:14px" v-if="scope.row.yet==1">已存在</span>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                        <div style="text-align:right;margin-right:40px;margin-bottom:20px">
                            <el-button type="primary" @click="addNeTargets">确认新增否定商品定位</el-button>
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </div>
    </el-dialog>

    <!-- 底部抽屉区域 -->
    <el-drawer
      title="广告表现"
      :modal="false"
      size="60%"
      :visible.sync="drawer"
      :direction="direction"
      :custom-class="DrawerClass"
      :before-close="handleClose"
    >
        <TendencyChart 
        :list.sync='ListOfData' 
        :info='`ASIN：${showAsin}  广告组:${adGroupName}`'
        :legendList='legendList'
        :currency="currency"
        @analysisClick='analysisClick'></TendencyChart>
    </el-drawer>
  </div>
</template>

<script>
import {pageInit,
        listPage,
        focusOn,
        focusOff,
        batchUpdate,
        addInit,
        getGoods,
        getAsinByAsins,
        listByGroupId,
        product,
        getKeywordsByAsin,
        getCategories,
        getTargetProduct,
        getTargetsBrands,
        listNegativeTarget,
        listCampaignNegativeKeywords,
        addNegativeKeyword,
        listNegativeKeywords,
        updateNeKeyword,
        addNeTargets,
        archivedNeTarget,
        addKeyword,
        listExistKeywords,
        addTargets,
        listExistTarget,
        groupDetailInit,
        goodsPageInit,
        listByProduct
        } from '@/api/Advertising/advertising.js'
import tableDialog from '@/components/tableDialog.vue'
import TimeQuantum from '@/components/TimeQuantum.vue'
import TendencyChart from './TendencyChart.vue'
import GoodsImage from '@/components/GoodsImage'
export default {
    name:'advertising2',
    components:{
        tableDialog,
        TimeQuantum,
        TendencyChart,
        GoodsImage
    },
    data(){
        return {
            drawer: false,
            direction: 'btt',
            startTime: "",
            endTime: "",
            currency:'',
            tableData:[],
            loading:false,
            addGroupBox:false,
            dialogLoading:false,
            buttonLoading:false,
            elaborationBox:false,
            searchLoading:false,
            headList:[
                {name:'广告活动',prop:'campaignName',width:150},
                {name:'活动状态',prop:'campaignState',width:100},
                {name:'投放类型',prop:'targetTypeName'},
                {name:'定时策略',prop:'', width:100},
                {name:'曝光量',prop:'impressions',width:121,sortable:true},
                {name:'点击量',prop:'clicks',width:121,sortable:true},
                {name:'点击率',prop:'clickRate',width:121,sortable:true},
                {name:'花费',prop:'cost',width:101,sortable:true,currency:true},
                {name:'CPC均价',prop:'clickAveCost',width:120,sortable:true,currency:true},
                {name:'订单数',prop:'attributedConversions14d',width:111,sortable:true},
                {name:'CVR',prop:'cvr',width:101,sortable:true},
                {name:'CPA',prop:'cpa',width:101,sortable:true,currency:true},
                {name:'销售额',prop:'attributedSales14d',width:111,sortable:true,currency:true},
                {name:'ACoS',prop:'acos',width:101,sortable:true},
                {name:'ROAS',prop:'roas',width:101,sortable:true},
            ],
            tableHead:[],
            ListOfData: [],
            adProductOperateType:[],
            adProductStateList:[],
            campaignStatesList:[],
            campaignsList:[],
            favoriteTypeList:[],
            groupList:[],
            screenGroupList:[],
            matchTypeList:[],
            portfoliosList:[],
            searchList:[],
            shopList:[],
            pageInfo:{
                profileId:'',
                startDate:'',
                endDate:'',
                portfolioId:'',
                campaignId:'',
                groupId:'',
                state:'',
                campaignStatus:'',
                targetType:'',
                isFavorite:'',
                current:'',
                pageSize:'',
                sortColumn:'',
                sort:''
            },
            keyword:'',
            select:'',
            batchUpdateInfo:{
                adId:[],
                operateType:'',
            },
            showActiveInfo:false,
            showGroupInfo:false,
            active:false,
            addInfo:{
                expressionType:'',
                campaignId:'',
                adGroupId:'',
                groupsList:[],
                screenGroupsList:[],
                campaignsList:[],
                targetAddReqList:[],
                neKeywordAddReqList:[],
                neTargetAddReqList:[],
                keywordAddReqList:[],
                uploadedKeywordAddReqList:[],
                ExistingGoodsList:[],
                elaborationRow:'',
            },
            activeName:'first',
            added:'first',
            inputList:'asin',
            goodsList:[],
            goodsTotal:'',
            manualASIN:'',
            addedList:[],
            getGoodsInfo:{
                current:'1',
                status:'',
                queryFrom:'advert'
            },
            suggestLoading:false,
            keywordList:[],
            targetProductList:[],
            matchType:'negativeExact',
            noKeywords:'',
            noGoods:'',
            putStatus:'',
            noKeyList:[],
            addNegativeKeywordsInfo:{//新增否定关键词信息
                adGroupId:'',
                amzAdKeywordForGroupAddReqList:[],
                campaignId:'',
                profileId:'',
            },
            addNeTargetsInfo:{
                adGroupId:'',
                amzAdTargetExpressionList:[],
                campaignId:'',
                expressionType:'manual',
            },
            archivedNoKeywordsIds:[],
            noGoodsType:'asinSameAs',
            targetsBrandsInfo:{
                keyword:'',
                categoryId:'',
            },
            targetsBrandsList:[],
            noGoodsList:[],
            activeWord:'first',
            screen:["exact","phrase","broad"],
            addKewordInput:'',
            putType:'asinCategorySameAs',
            putTypeInside:'first',
            categoriesList:[],
            inputASIN:'',
            marks:{0:'0星',1:'1星',2:'2星',3:'3星',4:'4星',5:'5星',},
            brandList:'',
            detailTypeList:'',
            primeTypeList:'',
            elaborationInfo:{
              asinBrandSameAs:[],
              asinPriceLessThan:'',//最高价
              asinPriceBetween:'',//价格去加
              asinPriceGreaterThan:'',//最低价
              asinReviewRatingBetween:[0,5],//星区间
              asinIsPrimeShippingEligible:''//,派送
            },
            detailActive:{
              campaignTargetType:'',
              date:'',
              state:'',
              target:'',
              targetingType:'',
              top:'',
              page:'',
              type:'',
              value:'',
              groupTargetType:'',
              groupVlue:''
            },
            searchByAsin:'',
            selectAsinType:'',
            productStatusList:[],
            chartInatance: null,  //  保存chartsInstance 数据
            adGroupName: "",
            showAsin: "",
            legendList: [
                {name:"曝光量",prop:'impressions'},
                {name:"点击量",prop:'clicks'},
                {name:"点击率",prop:'clickRate',persent:true},
                {name:"花费",prop:'cost',currency:true},
                {name:"CPC均价",prop:'clickAveCost',currency:true},
                {name:"订单数",prop:'attributedConversions14d'},
                {name:"CVR",prop:'cvr',persent:true},
                {name:"CPA",prop:'cpa',currency:true},
                {name:"销售额",prop:'attributedSales14d',currency:true},
                {name:"ACoS",prop:'acos',persent:true},
                {name:"RoAS",prop:'roas',persent:true},
            ],
            shopID:'',
            marketPlaceID:''
        };
    },
    watch:{
        $route(to){
            const info = this.$route.query
            // if(JSON.stringify(to.query)!=JSON.stringify(this.$route.query)){
            if(info.type=='getOne'){
                this.pageInfo.keyword=info.value
                this.getListPage()
            }else{
                this.pageInfo.profileId = Number(info.profileId);
                this.pageInfo.keyword=''
                this.screenGroupList.forEach(item=>{
                    if(item.id==info.id){
                        this.pageInfo.campaignId=item.campaignId;
                        this.pageInfo.groupId=item.id;
                       
                    }
                })
                this.getListPage()
            }
            // }
        }
    },
    computed:{
        addedNoKeyword(){
            let num = 0
            this.addInfo.neKeywordAddReqList.forEach(item=>{
                if(item.operation==1){
                    num++
                }
            })
            return num
        },
        addedNoGoods(){
            let num = 0
            this.addInfo.neTargetAddReqList.forEach(item=>{
                if(item.yet==1){
                    num++
                }
            })
            return num
        }
    },
    created(){
      this.tableHead=this.headList
      this.getPageInit()
    },
    methods:{
        getID(id,currency,shopId,marId){
            this.pageInfo={
                profileId:'',
                startDate:this.pageInfo.startDate,
                endDate:this.pageInfo.endDate,
                portfolioId:'',
                campaignId:'',
                groupId:'',
                state:'',
                campaignStatus:'',
                targetType:'',
                isFavorite:'',
                current:'',
                pageSize:'',
                sortColumn:'',
                sort:''
            }
            this.pageInfo.profileId=id
            this.currency=currency
            this.shopID=shopId
            this.marketPlaceID=marId
            // this.getListPage()
            this.getPageInit('currentPage')
        },
        resetList(){
            this.screenGroupList=this.groupList
            this.screenCampaignsList=this.campaignsList
        },
        getCampaignsAndGroupList(id){
            this.pageInfo.campaignId=''
            this.pageInfo.groupId=''
            const arr = this.campaignsList.filter(item=>{
                return item.portfolioId==id;
            })
            this.screenCampaignsList=arr
            const list = []
            this.screenCampaignsList.forEach(item=>{
                this.groupList.forEach(i=>{
                    if(i.campaignId==item.id){
                        list.push(i)
                    }
                })
            })
            this.screenGroupList=list
        },
        getGroupId(id,portfolioId){
            this.screenGroupList=this.groupList.filter(item=>{
                return item.campaignId==id
            })
             if(portfolioId) this.pageInfo.portfolioId=portfolioId
        },
        getGoodsPageInit(){
          goodsPageInit().then(res=>{
            this.productStatusList=res.data.data.productStatusList
            this.searchList=res.data.data.searchList
          })
        },
        elaborationClose(){
            this.elaborationBox=false
            this.elaborationInfo={
                asinBrandSameAs:[],
                asinPriceLessThan:'',//最高价
                asinPriceBetween:'',//价格去加
                asinPriceGreaterThan:'',//最低价
                asinReviewRatingBetween:[0,5],//星区间
                asinIsPrimeShippingEligible:''//,派送
            }
        },
        unfold(){
            this.active=!this.active
        },
        addElaboration(){
            let prime=''
            this.primeTypeList.forEach(item=>{
                if(item.id==this.elaborationInfo.asinIsPrimeShippingEligible) prime=item.value
            })
            // 是否取消加号
            // this.categoriesList.forEach((item,index)=>{
            //     if(this.addInfo.elaborationRow.id==item.id) this.categoriesList[index].operation=1
            // })
            let arr = []
            this.elaborationInfo.asinBrandSameAs.forEach(item=>{
                this.brandList.forEach(i=>{
                    if(i.id==item) arr.push({id:item,name:i.name})
                })
            })
            arr.forEach(item=>{
              this.addInfo.targetAddReqList.push({
                 amzAdTargetExpressionList:[{
                     name:this.addInfo.elaborationRow.name,
                     value:this.addInfo.elaborationRow.id,
                     type:this.putType}],
                 bid:'',
                 path:this.addInfo.elaborationRow.path,
                 operation:1,
                 prime:prime,
                 ...this.elaborationInfo,
                 ...item
              })
            })
            this.elaborationClose()
        },
        getGroupDetailInit(id,row){
          this.suggestLoading=true
          this.addInfo.elaborationRow=row
          groupDetailInit({categoryId:id},this.pageInfo.profileId).then(res=>{
            this.suggestLoading=false
            if(res.data.code==200){
                this.elaborationBox=true
                this.brandList=res.data.data.brandList
                this.detailTypeList=res.data.data.detailTypeList
                this.primeTypeList=res.data.data.primeTypeList
            }
          })
        },
        getListExistTarget(){
            this.dialogLoading=true
            listExistTarget({groupId:this.addInfo.adGroupId}).then(res=>{
                this.dialogLoading=false
                if(res.data.code!=200){
                    this.$message.error(res.data.message)
                    return
                }
                this.addInfo.targetAddReqList=res.data.data.map(item=>{
                    item.yet=1
                    return item
                })
            })
        },
        NewProductPositioning(){
            this.buttonLoading=true
            // 考虑细化新增
            let arr = this.addInfo.targetAddReqList.filter(item=>{
                return item.yet!=1
            })
            console.log(arr);
            let list = arr.map(item=>{
                if(item.name==undefined){
                    return item
                }
                if( item.name!=undefined){
                    let postList = []
                    postList.push({
                        name:item.name,
                        type:'asinBrandSameAs',
                        value:item.id
                    })
                    // 区间或价格
                    if(item.asinPriceLessThan!=''&&item.asinPriceGreaterThan!=''){
                        postList.push({
                            name:item.asinPriceGreaterThan+'-'+item.asinPriceLessThan,
                            type:'asinPriceBetween',
                            value:item.asinPriceGreaterThan+'-'+item.asinPriceLessThan,
                        })
                    }else if (item.asinPriceLessThan!=''&&item.asinPriceGreaterThan=='') {
                        postList.push({
                            name:item.asinPriceLessThan,
                            type:'asinPriceLessThan',
                            value:item.asinPriceLessThan,
                        })
                    }else if (item.asinPriceLessThan==''&&item.asinPriceGreaterThan!=''){
                        postList.push({
                            name:item.asinPriceGreaterThan,
                            type:'asinPriceGreaterThan',
                            value:item.asinPriceGreaterThan
                        })
                    }
                    // 星数
                    if(item.asinReviewRatingBetween!=''){
                        postList.push({
                            name:item.asinReviewRatingBetween[0]+'-'+item.asinReviewRatingBetween[1],
                            type:'asinReviewRatingBetween',
                            value:item.asinReviewRatingBetween[0]+'-'+item.asinReviewRatingBetween[1],
                        })
                    }
                    // 配送
                    if(item.asinIsPrimeShippingEligible!=''){
                        postList.push({
                            name:item.asinIsPrimeShippingEligible,
                            type:'asinIsPrimeShippingEligible',
                            value:item.asinIsPrimeShippingEligible
                        })
                    }
                    if(item.amzAdTargetExpressionList.length!=0){
                        postList.push(item.amzAdTargetExpressionList[0])
                    }
                    return {amzAdTargetExpressionList:postList,bid:item.bid}
                }
            })
            addTargets({
                adGroupId:this.addInfo.adGroupId,
                campaignId:this.addInfo.campaignId,
                expressionType:this.addInfo.expressionType,
                targets:list
            },this.pageInfo.profileId).then(res=>{
                this.buttonLoading=false
                if(res.data.code==200){
                    this.$message.success(res.data.message)
                    this.getListExistTarget()
                }else {
                    this.$message.error(res.data.message)
                }
            })
        },
        handleClick(tab, event){
            this.putType=tab.name
            if(tab.name=='asinCategorySameAs'){
                this.putTypeInside='first'
            }else{
                this.putTypeInside='second'
            }
        },
        removeAll(){
            this.addInfo.targetAddReqList=this.addInfo.targetAddReqList.filter((item,index)=>{
                return item.yet==1
            })
            // this.getListExistTarget()
            this.categoriesList=this.categoriesList.map(item=>{
                item.operation=0
                return item
            })
        },
        remove(index,row){
            this.categoriesList.forEach(i=>{
                if(row[index].amzAdTargetExpressionList[0].name==i.name){
                    i.operation=0
                }
            })
            this.targetProductList.forEach(i=>{
                if(row[index].amzAdTargetExpressionList[0].name==i.recommendedTargetAsin){
                    i.operation=0
                }
            })
            this.addInfo.targetAddReqList.splice(index,1)
        },
        addLocationGoods(rows){
            rows.operation=1
            if(this.putType=='asinCategorySameAs'){
                this.addInfo.targetAddReqList.push({amzAdTargetExpressionList:[{name:rows.name,value:rows.id,type:this.putType}],bid:'',path:rows.path})
            }
            if(this.putType=='asinSameAs'){
                if(this.inputASIN!=''){
                    this.addInfo.targetAddReqList.push({amzAdTargetExpressionList:[{name:this.inputASIN,type:this.putType,value:this.inputASIN}],bid:''})
                }else{
                    this.addInfo.targetAddReqList.push({amzAdTargetExpressionList:[{name:rows.recommendedTargetAsin,type:this.putType,value:rows.recommendedTargetAsin}],bid:''})
                }
            }
            this.inputASIN=''
        },
        getListExistKeywords(){
          this.dialogLoading=true
          listExistKeywords({groupId:this.addInfo.adGroupId}).then(res=>{
            this.dialogLoading=false
            if(res.data.code!=200){
                this.$message.error(res.data.data)
            }
            this.addInfo.keywordAddReqList=res.data.data.map(item=>{
                item.yet=1
                return item;
            })
          })
        },
        delAllGoodsLaunch(){
           this.addedList=this.addedList.filter(item=>{
             return item.yet==1;
           })
           this.goodsList=this.goodsList.map(item=>{
             item.operation=0
             return item;
           })
        },
        delAllkeywordLaunch(){
            this.addInfo.keywordAddReqList=this.addInfo.keywordAddReqList.filter(item=>{
                return item.yet==1;
            })
        },
        confirmAddNewKeywords(){
            this.buttonLoading=true
            addKeyword({
                adGroupId:this.addInfo.adGroupId,
                campaignId:this.addInfo.campaignId,
                profileId:this.pageInfo.profileId,
                amzAdKeywordForGroupAddReqList:this.addInfo.uploadedKeywordAddReqList},
                this.pageInfo.profileId)
            .then(res=>{
                this.buttonLoading=false
                if(res.data.code==200){
                    this.$message.success(res.data.message)
                    this.getListExistKeywords()
                }else{
                    this.$message.warning(res.data.message)
                }
            })
        },
        delKeywords(index, rows){
            rows.splice(index, 1);
        },
        addInputKeywords(){
            if(this.addKewordInput==''){
                this.$message.warning('请输入关键词')
                return ;
            }
            const arr=[]
            this.screen.forEach(item=>{
                arr.push({bid:'',keywordText:this.addKewordInput,matchType:item})
            })

            if(this.addInfo.keywordAddReqList.length==0){
                this.addInfo.keywordAddReqList=arr
            }else{
                this.addInfo.keywordAddReqList.forEach((j,index)=>{
                    arr.forEach((i,ind)=>{
                        if(i.keywordText==j.keywordText && i.matchType==j.matchType){
                            arr.splice(ind,1)
                        }
                    })
                })
                this.addInfo.uploadedKeywordAddReqList=[...this.addInfo.uploadedKeywordAddReqList,...arr]
                this.addInfo.keywordAddReqList=[...this.addInfo.keywordAddReqList,...arr]
                this.addKewordInput=''
            }
        },
        addKeywords(item){
            const arr = []
            this.screen.forEach(i=>{
                arr.push({bid:'',keywordText:item,matchType:i})
            })
            if(this.addInfo.keywordAddReqList.length==0){
                this.addInfo.keywordAddReqList=arr
            }else{
                this.addInfo.keywordAddReqList.forEach((j,index)=>{
                    arr.forEach((i,ind)=>{
                        if(i.keywordText==j.keywordText && i.matchType==j.matchType){
                            arr.splice(ind,1)
                        }
                    })
                })
                if(arr.length==''){
                    this.$message.warning("关键词已存在")
                    return
                }
                this.addInfo.uploadedKeywordAddReqList=[...this.addInfo.uploadedKeywordAddReqList,...arr]
                this.addInfo.keywordAddReqList=[...this.addInfo.keywordAddReqList,...arr]
            }
        },
        allKeywordlaunch(){
            const arr = []
            this.screen.forEach(item=>{
                this.keywordList.forEach(i=>{
                    arr.push({bid:'',keywordText:i,matchType:item})
                })
            })
            if(this.addInfo.keywordAddReqList.length==0){
                this.addInfo.keywordAddReqList=arr
            }else {
                this.addInfo.keywordAddReqList.forEach(item=>{
                    arr.forEach((i,index)=>{
                        if(item.keywordText==i.keywordText&&item.matchType==i.matchType){
                            arr.splice(index,1)
                        }
                    })
                })
                this.addInfo.keywordAddReqList=[...this.addInfo.keywordAddReqList,...arr]
            }
        },
        GoodsAll(info){
            if(info=='del'){
                this.addedList.forEach(item=>{
                    item.operation=0
                })
                this.addedList=[]
                this.getGoodsList()
            }else {
                const arr = this.goodsList.filter(item=>{
                    return item.operation==0
                })
                arr.forEach(item=>{
                    item.operation=1
                })
                this.addedList=[...this.addedList,...arr]
            }
        },
        addNeTargets(){
          this.buttonLoading=true
          this.addNeTargetsInfo.amzAdTargetExpressionList=this.noGoodsList   
          this.addNeTargetsInfo.adGroupId=this.addInfo.adGroupId
          this.addNeTargetsInfo.campaignId=this.addInfo.campaignId
          addNeTargets(this.addNeTargetsInfo,this.pageInfo.profileId).then(res=>{
              this.buttonLoading=false
              if(res.data.code==200){
                  this.$message.success(res.data.message)
                  this.getListNegativeTargetList()
              }else{
                  this.$message.error(res.data.message)
              }
          })
        },
        delItem(index,name){    
            this.addInfo.neTargetAddReqList.splice(index,1)
            this.targetsBrandsList.forEach(item=>{
                if(this.addInfo.neTargetAddReqList.length==0){
                    item.operation=0
                }else{
                    this.addInfo.neTargetAddReqList.forEach(i=>{
                        if(item.name==i.name&&this.noGoodsType=='asinBrandSameAs'){
                            item.operation=0
                        }
                    })
                }
            })
            this.noGoodsList.forEach((item,ind)=>{
                if(item.name==name){
                    this.noGoodsList.splice(ind,1)
                }
            })
        },
        selectNoGoods(val){
          this.archivednoGoodsIds=val.map(item=>{
            return item.targetId
          })
        },
        delAllnoGood(){
            if(this.noGoodsList.length==0){
                this.$message.warning('只能删除新增的，已存在的可点击归档以达到删除的目的')
            }else {
                this.addInfo.neTargetAddReqList.forEach((item,index)=>{
                    if(item.yet!=1){
                        this.addInfo.neTargetAddReqList.splice(index,1)
                    }
                })
                this.noGoodsList=[]
            }
        },
        archivedNeTarget(){
            this.dialogLoading=true
            archivedNeTarget({type:'negativeTargets',targetIds:this.archivednoGoodsIds},this.pageInfo.profileId).then(res=>{
                if(res.data.code==200){
                    this.$message.success(res.data.message)
                    this.getListNegativeTargetList()
                }else{
                    this.$message.error(res.data.message)
                }
                this.dialogLoading=false
            }).catch(()=>{
                this.dialogLoading=false
            })
        },
        addNoGoods(){
            if(!this.noGoods){
                this.$message.warning('请填写ASIN')
                return
            }
            const reg = /^B[0-9]{1}[0-9]{1}\w{7}$/
            let list=this.noGoods.split(/[(\r\n)\r\n]+/)
            const arr = []
            list = Array.from(new Set(list))
            let num = 0
            list.forEach(item=>{
                if(!reg.test(item)) num++
            })
            if(num>0){
                this.$message.warning(`有${num}个ASIN格式有误`)
                return
            }
            list.forEach(item=>{
                const flag = this.addInfo.neTargetAddReqList.some(i=>{
                    return item==i.value&&i.type=='asinSameAs'
                })
                if(!flag){
                    arr.push({name:item,value:item,type:'asinSameAs'})
                }
            })
            this.noGoodsList=[...this.noGoodsList,...arr]
            this.noGoods=''
            this.addInfo.neTargetAddReqList=[...this.addInfo.neTargetAddReqList,...arr]
        },
        toTargetsBrandsList(row,index){
          if(this.noGoods=='' && this.noGoodsType=='asinSameAs'){
            this.$message.warning('请输入内容')
            return ;
          }
          if(this.noGoods=='' && this.noGoodsType=='asinBrandSameAs'){
            this.addInfo.neTargetAddReqList.push({name:row.name,type:this.noGoodsType,value:row.id})
            this.noGoodsList.push({name:row.name,type:this.noGoodsType,value:row.id})
            this.targetsBrandsList[index].operation=1
          }else{
            this.addNoGoods()
          }
        },
        getTargetsBrandsList(){
          this.searchLoading=true
          getTargetsBrands(this.targetsBrandsInfo,this.pageInfo.profileId).then(res=>{
            this.searchLoading=false
            if(res.data.code==200){
                this.targetsBrandsList=res.data.data.map(item=>{
                    item.operation=0
                    return item
                })
                this.addInfo.neTargetAddReqList.forEach(i=>{
                    this.targetsBrandsList.forEach(item=>{
                        if(item.name==i.name&&i.type=='asinBrandSameAs'||item.name==i.value){
                            item.operation=1
                        }
                    })
                })
            }else {
                    this.$message.error(res.data.message)
            }
          })
        },
        archivedSelect(row,ind){
            if(row.yet==1){
                return true
            }else {
                return false
            }
        },
        activeInfo(){
            this.showActiveInfo=!this.showActiveInfo
        },
        groupInfo(){
            this.showGroupInfo=!this.showGroupInfo
        },
        updateNeKeyword(){
            this.dialogLoading=true
            updateNeKeyword({type:'negativeKeywords',state:'archived',keywordIds:this.archivedNoKeywordsIds},this.pageInfo.profileId).then(res=>{
                this.dialogLoading=false
                if(res.data.code==200){
                    this.$message.success(res.data.message)
                    this.getListNegativeKeywords()
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(()=>{
                this.dialogLoading=false
                this.$message.error(res.data.message)
            })
        },
        getListNegativeKeywords(){
            listNegativeKeywords({groupId:this.addInfo.adGroupId}).then(res=>{
              if(res.data.code==200){
                  // 未封装组件   与添加广告组使用同一个变量  写代码前缺乏思考(￣▽￣)~*
                  this.addInfo.neKeywordAddReqList=res.data.data.map(item=>{
                      item.operation=1
                      return item
                  })
              }else{
                  this.$message.error(res.data.message)
              }
            })
        },
        addNegativeKeyword(){
            this.buttonLoading=true
            this.addNegativeKeywordsInfo.amzAdKeywordForGroupAddReqList=this.noKeyList
            this.addNegativeKeywordsInfo.adGroupId=this.addInfo.adGroupId
            this.addNegativeKeywordsInfo.campaignId=this.addInfo.campaignId
            this.addNegativeKeywordsInfo.profileId=this.addInfo.profileId
            addNegativeKeyword(this.addNegativeKeywordsInfo,this.pageInfo.profileId).then(res=>{
                this.buttonLoading=false
                if(res.data.code==200){
                    this.$message.success(res.data.message)
                    this.getListNegativeKeywords()
                    this.noKeyList=[] 
                }else {
                    this.$message.error(res.data.message)
                }
            })
        },
        delNegativeKeyword(index,keywordText){
            this.addInfo.neKeywordAddReqList.splice(index,1)
            this.noKeyList.forEach((item,ind)=>{
                if(item.keywordText==keywordText){
                    this.noKeyList.splice(ind,1)
                }
            })
        },
        archivedKeywordSelect(row,id){
            if(row.operation==1){
                return true
            }else {
                return false
            }
        },
        selectNoKeywords(val){
            this.archivedNoKeywordsIds=val.map(item=>{
              return item.keywordId
            })
        },
        delAllkeyword(){
          if(this.noKeyList.length==0){
              this.$message.warning('只能删除新增的，已存在的可点击归档以达到删除的目的')
          }else {
              this.noKeyList=[]
              this.addInfo.neKeywordAddReqList=this.addInfo.neKeywordAddReqList.filter((item,index)=>{
                //   if(item.operation!=1){
                //       this.addInfo.neKeywordAddReqList.splice(index,1)
                //   }
                  return item.operation==1
              })
          }
        },
        getListNegativeTargetList(){
            listNegativeTarget({groupId:this.addInfo.adGroupId}).then(res=>{
                if(res.data.code==200){
                    this.addInfo.neTargetAddReqList=res.data.data.map(item=>{
                        item.yet=1
                        return item
                    })
                }
            })
        },
        getListCampaignNegativeKeywords(){
            listCampaignNegativeKeywords({campaignId:this.addActiveInfo.campaignId}).then(res=>{
                if(res.data.code==200){
                    // 未封装组件   与添加广告组使用同一个变量  写代码前缺乏思考(￣▽￣)~*
                    this.addInfo.neKeywordAddReqList=res.data.data.map(item=>{
                        item.operation=1
                        return item
                    })
                }else{
                    this.$message.error(res.data.message)
                }
            })
        },
        getKeywordsByAsin(arr){
            this.suggestLoading=true
            getKeywordsByAsin(arr,this.pageInfo.profileId).then(res=>{
                this.suggestLoading=false
                this.keywordList=res.data.data
            })
        },
        getTargetProduct(arr){
            this.suggestedLoading=true
            getTargetProduct(arr,this.pageInfo.profileId).then(res=>{
                this.suggestedLoading=false
                this.targetProductList=res.data.data.map(item=>{
                    item.operation=0
                    return item
                })
                if(this.addInfo.targetAddReqList.length!=0){
                    this.addInfo.targetAddReqList.forEach(i=>{
                        this.targetProductList.forEach(j=>{
                            if(j.recommendedTargetAsin==i.amzAdTargetExpressionList[0].name){
                                j.operation=1
                            }
                        })
                    })
                }
            })
        },
        addNoKey(){
            if(!this.noKeywords){
                this.$message.warning('请填写关键词')
                return
            }
            const arr = []
            const list=this.noKeywords.split(/[(\r\n)\r\n]+/)
            list.forEach(item=>{
                const flag =  this.addInfo.neKeywordAddReqList.some(i=>{
                    return item==i.keywordText&&this.matchType==i.matchType;
                })
                if(!flag){
                    arr.push({keywordText:item,matchType:this.matchType})
                }
            })
            this.noKeyList=[...this.noKeyList,...arr]
            this.noKeywords=''
            this.addInfo.neKeywordAddReqList=[...this.addInfo.neKeywordAddReqList,...arr]
        },
        addGroupBoxClose(){
            this.addInfo={
                // expressionType:'',
                // campaignId:'',
                // adGroupId:'',
                // groupsList:[],
                // screenGroupsList:[],
                // screenCampaignsList:[],
                // campaignsList:[],
                // targetAddReqList:[],
                // neKeywordAddReqList:[],
                // neTargetAddReqList:[],
                // keywordAddReqList:[],
                // uploadedKeywordAddReqList:[],
                // ExistingGoodsList:[],
                // elaborationRow:'',
                expressionType:'',
                campaignId:'',
                adGroupId:'',
                groupsList:[],
                screenGroupsList:[],
                campaignsList:[],
                targetAddReqList:[],
                neKeywordAddReqList:[],
                neTargetAddReqList:[],
                keywordAddReqList:[],
                uploadedKeywordAddReqList:[],
                ExistingGoodsList:[],
                elaborationRow:'',
                
            },
            this.addInfo.uploadedKeywordAddReqList
            this.getGoodsInfo={
                status:'',
                current:'1',
                queryFrom:'advert'
            }
            this.elaborationInfo={
                asinBrandSameAs:[],
                asinPriceLessThan:'',//最高价
                asinPriceBetween:'',//价格去加
                asinPriceGreaterThan:'',//最低价
                asinReviewRatingBetween:'',//星区间
                asinIsPrimeShippingEligible:''//,派送
            }
            this.putType='asinCategorySameAs'
            this.noGoodsType='asinSameAs'
            this.putTypeInside='first'
            this.activeWord='first'
            this.noGoodsList=[]
            this.targetsBrandsList=[]
            this.targetsBrandsInfo.keyword=''
            this.addNegativeKeywordsInfo={//新增否定关键词信息
                adGroupId:'',
                amzAdKeywordForGroupAddReqList:[],
                campaignId:'',
                profileId:'',
            }
            this.addNeTargetsInfo={
                adGroupId:'',
                amzAdTargetExpressionList:[],
                campaignId:'',
                expressionType:'manual',
            }
            this.active=false
            this.addedList=[]
            this.addGroupBox=false
        },
        GoodsAll(info){
            if(info=='del'){
                this.addedList.forEach(item=>{
                    if(item.yet!=1){
                        item.operation=0
                    }
                })
                this.getGoodsList()
            }else {
                const arr = this.goodsList.filter(item=>{
                    return item.operation==0
                })
                arr.forEach(item=>{
                    item.operation=1
                })
                this.addedList=[...this.addedList,...arr]
            }
        },
        changeCurrent(val){
            this.getGoodsInfo.current=val
            this.getGoodsList()
        },
        toGoods(item,ind){
            item.operation=0
            this.addedList.splice(ind,1)
            this.toAdded()
        },
        sureAdd(){
            const arr = []
            this.addedList.forEach(item=>{
                if(item.yet!=1){
                    arr.push({
                        adGroupId:this.addInfo.adGroupId,
                        campaignId:this.addInfo.campaignId,
                        // asin:item.asin,
                        sku:item.commoditySku,
                        state:'enabled'
                    })
                }
            })
            this.dialogLoading=true
            product(arr,this.pageInfo.profileId).then(res=>{
                this.dialogLoading=false
                if(res.data.code==200){
                    this.$message.success(res.data.message)
                    console.log(this.putStatus,this.addInfo.adGroupId);
                    this.getExistGoods(this.addInfo.adGroupId,this.putStatus)
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(()=>{
                this.dialogLoading=false
            })
        },
        getExistGoods(id,type,item){
            if(item){
                this.detailActive.groupTargetType=item.targetType
                this.detailActive.groupValue=item.default
            }
            this.putStatus=type
            this.addInfo.expressionType=type=="自"?'auto':'manual'
            this.dialogLoading=true
            listByGroupId(id,this.pageInfo.profileId).then(res=>{
                this.dialogLoading=false
                if(res.data.code==200){
                    let arr = res.data.data.map(item=>{
                        item.yet=1
                        return item
                    })
                    this.addedList=arr
                }
            })
            .then(()=>{
                // ============================待细分===================================================
                this.toAdded()
                this.getListNegativeKeywords()
                this.getListNegativeTargetList()
                this.getListExistKeywords()
                this.getListExistTarget()
            })
        },
        manualAdd(){
            // 根据ASIN获取商品
            let list = this.manualASIN.split(/[(\r\n)\r\n]+/)
            this.dialogLoading=true
            getAsinByAsins({asins:list,queryType:this.inputList}).then(res=>{
                this.dialogLoading=false
                if(res.data.code==200){
                    const arr = res.data.data.map(item=>{
                        item.operation=1
                        return item
                    })
                    if(arr.length!=0){
                        this.addedList.forEach(item=>{
                            arr.forEach((i,index)=>{
                                if(item.id==i.id){
                                    arr.splice(index,1)
                                }
                            })
                        })
                    }
                    this.addedList=[...this.addedList,...arr]
                    this.manualASIN=''
                    this.toAdded()
                }
            })
        },
        toAdded(item){
            if(item!=undefined){
                this.addedList.push({...item,operation:1})
            }
            this.getGoodsList()
            const arr=[]
            this.addedList.forEach(i=>{
                arr.push(i.asin)
            })
            this.getKeywordsByAsin(arr)
            getCategories(arr,this.pageInfo.profileId).then(res=>{
                this.categoriesList=res.data.data.map(item=>{
                    item.operation=0
                    return item
                })
                if(this.addInfo.targetAddReqList.length!=0){
                    this.addInfo.targetAddReqList.forEach(i=>{
                        this.categoriesList.forEach(j=>{
                            if(j.name==i.amzAdTargetExpressionList[0].name){
                                j.operation=1
                            }
                        })
                    })
                }
            })
            this.getTargetProduct(arr)
        },
        addGroup(){
            this.loading=true
            this.getGoodsPageInit()
            addInit(this.pageInfo.profileId).then(res=>{
                this.loading=false
                if(res.data.code==200){
                    this.addGroupBox=true
                    this.addInfo.campaignsList=res.data.data.campaignsList
                    this.addInfo.groupsList=res.data.data.groupsList
                    this.getGoodsList()
                }else {
                    this.$message.error(res.data.message)
                }
            }).catch(res=>{
                this.loading=false
            })
        },
        getGoodsList(){
            this.dialogLoading=true
            getGoods({...this.getGoodsInfo,[this.selectAsinType]:this.searchByAsin?this.searchByAsin:null,shopId:this.shopID,marketPlaceId:this.marketPlaceID}).then(res=>{
                this.dialogLoading=false
                const arr = res.data.values.map(item=>{
                    item.operation=0
                    return item
                })
                if(this.addedList.length!=0){
                    const ids=this.addedList.map(item=>{
                        return item.id
                    })
                    arr.forEach(item=>{
                        ids.forEach(i=>{
                            if(i==item.id){
                                item.operation=1
                            }
                        })
                    })
                }
                
                this.goodsList=arr
                this.goodsTotal=res.data.pageInfo.total
            })
        },
        getScreenGroupsList(val){
            // 获取广告活动信息
            this.detailActive={
                bid:val.bid,
                date:val.date,
                target:val.target,
                targetingType:val.targetingType,
                top:val.top,
                page:val.page,
                value:val.value
            }
            this.addInfo.adGroupId=''
            this.addInfo.screenGroupsList=this.addInfo.groupsList.filter(item=>{
                return item.campaignId==val.id;
            })
        },
        changeStatus(id,state,index){
            console.log(index);
            this.batchUpdateInfo.adId=[id]
            if(state=='enabled'){
                this.batchUpdateInfo.operateType=4
            }else if(state=='paused'){
                this.batchUpdateInfo.operateType=3
            }
            this.loading=true
            batchUpdate(this.batchUpdateInfo,this.pageInfo.profileId).then(res=>{
                this.loading=false
                if(res.data.code==200){
                    this.$message.success(res.data.message)
                    this.tableData[index].state=state
                }else {
                    this.$message.error(res.data.message)
                    if(status=='paused'){
                        this.tableData[index].state='enabled'
                    }else{
                        this.tableData[index].state='paused'
                    }
                }
            })
        },
        selectable(row,index){
            if(row.state=='archived'){
                return false
            }else{
                return true
            }
        },
        batchUpdate(ind){
            if(this.batchUpdateInfo.adId.length==0) return this.$message.warning('请选择具体内容')
            this.batchUpdateInfo.operateType=ind
            this.loading=true
            batchUpdate(this.batchUpdateInfo,this.pageInfo.profileId).then(res=>{
                this.loading=false
                if(res.data.code==200){
                    this.$message.success(res.data.message)
                    this.getListPage()
                }else {
                    this.$message.error(res.data.message)
                    this.getListPage()
                }
            }).catch(()=>{
                this.$message.error(res.data.message)
                this.getListPage()
                this.loading=false
            })
        },
        handleSelectionChange(val){
          this.batchUpdateInfo.adId=val.map(item=>{
            return item.adId
          })
        },
        handleSizeChange(val){
          this.pageInfo.pageSize=val
          this.getListPage()
        },
        handleCurrentChange(val){
          this.pageInfo.current=val
          this.getListPage()
        },
        focusChange(id,status,index){
            this.loading=true
            if(status==0){
                focusOn(id).then(res=>{
                    this.loading=false
                    if(res.data.code==200){
                        this.tableData[index].focusOn=1
                    }else{
                        this.$message.error(res.data.message)
                    }
                })
            }
            if(status==1){
                focusOff(id).then(res=>{
                    this.loading=false
                    if(res.data.code==200){
                        this.tableData[index].focusOn=0
                    }else{
                        this.$message.error(res.data.message)
                    }
                })
            }
        },
        resetGroupList(){
          this.pageInfo.groupId=''
          this.screenGroupList=this.groupList
        },
        getTimeSection(val){
          this.pageInfo.startDate=val.value[0]
          this.pageInfo.endDate=val.value[1]
        },
        getListPage(){
          this.$store.commit('setProfileId',this.pageInfo.profileId)
          this.loading=true
          listPage({...this.pageInfo,[this.select]:this.keyword?this.keyword:null}).then(res=>{
            this.loading=false
            this.tableData=res.data.values
            this.total=res.data.pageInfo.total
          })
        },
        getPageInit(val){
            this.loading=true
            pageInit(this.pageInfo.profileId||this.$store.state.profileId?this.pageInfo.profileId||this.$store.state.profileId:0).then(res=>{
                this.loading=false
                if(res.data.code==200){
                    this.adProductOperateType=res.data.data.adProductOperateType
                    this.adProductStateList=res.data.data.adProductStateList
                    this.campaignStatesList=res.data.data.campaignStatesList
                    this.campaignsList=res.data.data.campaignsList
                    this.screenCampaignsList=this.campaignsList
                    this.favoriteTypeList=res.data.data.favoriteTypeList
                    this.groupList=res.data.data.groupList
                    this.screenGroupList=this.groupList
                    this.matchTypeList=res.data.data.matchTypeList
                    this.portfoliosList=res.data.data.portfoliosList
                    this.searchList=res.data.data.searchList
                    this.shopList=res.data.data.shopList
                    if(this.pageInfo.profileId==''){
                        this.shopList.forEach(item=>{
                            item.marketPlaceTree.forEach(i=>{
                                if(i.isSelected==1){
                                    this.pageInfo.profileId=i.profileId
                                    this.pageInfo.current=1
                                    this.currency=i.currency
                                    this.shopID=item.id
                                    this.marketPlaceID=i.id
                                }
                            })
                        })
                    }
                    if(val=='currentPage'){
                        this.getListPage()
                        return
                    }else {
                        let info = this.$route.query
                        typeof info.profileId
                        if(JSON.stringify(info)!='{}'){
                            if(info.type=='getOne'){
                                this.pageInfo.profileId = Number(info.profileId)
                                this.pageInfo.keyword=info.value
                                this.getListPage()
                            }else{
                                this.pageInfo.profileId = Number(info.profileId)
                                this.pageInfo.keyword=''
                                this.screenGroupList.forEach(item=>{
                                    if(item.id==info.id){
                                    this.pageInfo.campaignId=item.campaignId
                                    this.pageInfo.groupId=item.id
                                    }
                                })
                                this.getListPage()
                            }
                        }else{
                            this.getListPage()
                        }
                    }
                }else{
                    this.$message.error(res.data.message)
                }
            })
        },
        setHead(val){
            this.tableHead=val
        },
        find(){
            this.pageInfo.current=1
            this.getListPage()
        },
        searchGoods(){
            this.getGoodsInfo.current=1
            this.getGoodsList()
        },
        sortTable(column){
            if(column.order=='ascending'){
                this.pageInfo.sort='asc'
            }else if(column.order=='descending'){
                this.pageInfo.sort='desc'
            }else if(column.order==null){
                this.pageInfo.sort=''
                this.pageInfo.sortColumn=''
                this.getListPage()
                return
            }
            this.pageInfo.sortColumn=column.prop
            this.getListPage()
        },
        // 选择时间
        changeTime(val) {
           this.startTime = val.value[0];
           this.endTime = val.value[1];
        },
        getChart(row){
            this.showAsin = row.asin;
            this.adGroupName = row.adGroupName;
            this.$store.commit('setAdvName',row.adId)
            this.analysisClick()
        },
        // 经营分析
        analysisClick(val) {
          let params = {
            profileId: this.pageInfo.profileId,
            startDate: val?val.value[0]:'',
            endDate: val?val.value[1]:'',
            productAdId: this.$store.state.advName
          }
          listByProduct(params)
           .then(res=> {
             if(res.data.code == 500) {
               this.$message.error(res.data.message);
             }else {
               this.ListOfData = res.data.data;
             }
           })
           this.drawer = true;
        },
    }
}
</script>

<style scoped lang='scss'>
// ::v-deep .el-switch__core{
//     width:30px!important;
//     height:15px;
//     /*color:#409EFF;*/
//   }
//   /*设置圆*/
// ::v-deep .el-switch__core::after{
//     width:13px;
//     height:13px;
//     margin-top:-1px;
//     margin-bottom: 2px;
// }
.Box{
    display: flex;
    margin: 20px 40px;
    >div{
        overflow: auto;
        flex: 1;
        border: 1px solid #F0F0F0;
        padding: 10px;
        position: relative;
        >.all{
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }
}
.item{
    height: 85px;
    border-bottom:1px solid #F0F0F0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
    .pic{
        width: 70px;
        height: 70px;
        margin-right:10px;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .SKU{
        height: 50px;
        width: 170px;
        line-height: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .state{
        display: inline-block;
        width: 24px;
        height: 16px;
        vertical-align: middle;
        margin-top: -4px;
    }
    .realizable{
        width: 120px;
        height: 50px;
        line-height: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .price{
        width: 150px;
        height: 60px;
        p{
            line-height: 60px;
        }
    }
}
.noPic{
    width: 60px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    font-size:8px;
    color: #909399;
    margin-right: 15px;
    span{
        display: block;
        background: #EBEEF5;
        width: 100%;
        height: 100%;
    }
}
.goodsInfo{
    display: flex;
    >div:nth-of-type(2){
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    p{
        font-size: 12px;
        color: #909399;
    }
}
.picture{
    width:160px;
    height: 160px;
    font-size:8px;
    color: #909399;
    text-align: center;
    line-height: 160px;
    margin-right: 10px;
    overflow: hidden;
    span{
        display: block;
        background: #EBEEF5;
        width: 100%;
        height: 100%;
    }
}
.infoText{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 25px;
    margin: 15px 0 20px 0;
}
.suspend{
    p{
        span{
            display: inline-block;
            width: 180px;
            margin-right: 10px;
            font-size: 13px;
        }
    }
}
.words{
    border-left: 1px solid #F0F0F0;
    border-right: 1px solid #F0F0F0;
    margin: 0 40px;
    .keyword{
        display: flex;
        >div{
            flex: 1;
            margin: 10px;
            padding-right:15px;
        }
        >div:nth-of-type(1){
            border-right: 1px solid #F0F0F0;
        }
    }
}
::v-deep .el-collapse-item__content{
    padding-bottom:0 ;
}
.goodsPut{
    border:1px solid #F0F0F0;
    margin: 0 40px;
    padding: 15px;
}
img{
    vertical-align: middle;
}
.el-icon-star-off{
    color:grey;
}
.el-icon-star-on{
    color: #F7BA2A;
    font-size: 20px !important;
}
::v-deep .el-slider__marks div:nth-of-type(6){
    left:96% !important;
}

.activeInfo{
    width:90%;
    margin:0px 0 15px 25px;
    border:1px solid #EEEEEE;
    padding: 16px;
    box-sizing: border-box;
    >div{
        display: inline-block;
        line-height: 35px;
        >span:nth-of-type(1){
            display: inline-block;
            width: 100px;
            text-align: right;
        }
        >span:nth-of-type(2){
            width: 190px;
            display: inline-block;
            color: #999999;
        }
    }
}
::v-deep .el-select-group__title{
    font-size:12px;
    margin-left: -9px;
    transform: scale(0.86);
}
::v-deep .el-select-dropdown__item{
    margin-left: 14px;
}
.keywordClass {
  margin-top: 20px;
  margin-bottom: 30px;
  left: 150px;
  width: 2650px;
  height: 650px;
  border-radius: 20px;
}
</style>